import React, { createContext, useEffect, useReducer } from 'react';
// import { Chance } from 'chance';
import { LOGIN, LOGOUT, REGISTER } from 'store/actions';
import accountReducer from 'store/accountReducer';
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import { InitialLoginContextProps, JWTContextType } from 'types/auth';
import axiosServices from 'utils/axios';

const initialState: InitialLoginContextProps = {
    loggedInUserType: '',
    isLoggedIn: false,
    isInitialized: false,
    user: null
};
const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('loginUser');
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};
const JWTContext = createContext<JWTContextType | null>(null);
const generateRandomToken = () => {
    const length = 25;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        token += characters.charAt(randomIndex);
    }
    return token;
};
export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                const response = await axiosServices.post(
                    `${process.env.REACT_APP_API_BASE_URL}/user/user-details-by-accessToken`,
                    {
                        accessToken: serviceToken
                    }
                );
                if (response?.data) {
                    setSession(serviceToken);
                    const user = response?.data?.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            loggedInUserType: String(user.user_type).toUpperCase(),
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email: string, password: string) => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/login`, { email, password });
        const { accessToken, data: user } = response.data;
        setSession(accessToken);
        console.log('user>>', user);

        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                loggedInUserType: String(user.user_type).toUpperCase(),
                user
            }
        });
        // window.location.href = '/home';
    };

    const register = async (email: string, password: string, firstName: string, lastName: string) => {
        // todo: this flow need to be recode as it not verified
        // const id = chance.bb_pin();
        // const response = await axios.post('/api/account/register', {
        //     id,
        //     email,
        //     password,
        //     firstName,
        //     lastName
        // });
        // let users = response.data;

        // if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
        //     const localUsers = window.localStorage.getItem('users');
        //     users = [
        //         ...JSON.parse(localUsers!),
        //         {
        //             id,
        //             email,
        //             password,
        //             name: `${firstName} ${lastName}`
        //         }
        //     ];
        // }

        // window.localStorage.setItem('users', JSON.stringify(users));

        await axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/user/register`, {
                firstName,
                lastName,
                email,
                password
            })
            .then((res) => {
                if (res.status === 200) {
                    const serviceToken = generateRandomToken();
                    setSession(serviceToken);
                    const user = res?.data?.data;
                    dispatch({
                        type: REGISTER,
                        payload: {
                            isLoggedIn: true,
                            loggedInUserType: user?.user_type,
                            user
                        }
                    });
                    window.location.href = '/home';
                }
            })
            .catch((err) => {
                throw new Error(err.message || 'Register Account failed');
            });
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (email: string) => {};

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;
