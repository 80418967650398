const routeConstant = {
    blank: '/',
    initial: '/home',
    // Auth Route Start
    login: '/login',
    register: '/register',
    forgotPassword: '/forgot-password',
    resetPassword: '/user/reset-password',
    checkMail: '/check-mail',
    resetPasswordLinkSent: '/reset-password-link-sent',
    // Auth Route End
    accessDenied: '/access-denied',
    dashboard: '/dashboard',
    userSettings: '/apps/user/settings',
    updates: '/updates',
    updatesProfile: '/updates/profile',
    updatesForms: '/updates/add',
    updatesEdit: '/updates/edit',
    clients: '/clients',
    clientsProfile: '/clients/profile',
    clientsForms: '/client/add',
    clientsEdit: '/client/edit',
    properties: '/properties',
    propertiesProfile: '/properties/profile',
    propertiesForms: '/property/add',
    propertiesEdit: '/property/edit',
    unitAdd: '/unit/add',
    unitEdit: '/unit/edit',
    documents: '/documents',
    newDocument: '/documents/new-document',
    financials: '/financials',
    agents: '/agents',
    agentsProfile: '/agents/profile',
    agentsForms: '/agents/forms',
    agencies: '/agencies',
    agenciesProfile: '/agencies/profile',
    agenciesForms: '/agencies/forms',
    provider: '/provider',
    providerProfile: '/provider/profile',
    providerForms: '/provider/forms',
    managers: '/managers',
    managersProfile: '/managers/profile',
    managersForms: '/managers/forms',
    any: '*'
};

export default routeConstant;
